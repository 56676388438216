
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import CustomerTable from "@/components/Customers/CustomerTable.vue";
import Card from "primevue/card";
import { FieldServiceObject } from "@/types/state/fieldServices";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Utils from "@/utility/utils";
import Customer from "@/types/customer";

export default defineComponent({
  name: "FieldServiceCustomerSearchResults",
  components: {
    Card,
    Column,
    DataTable,
  },
  computed: {
    ...mapGetters({
      getFieldServices: "fieldServices/getFieldServices",
      getAllCustomers: "customer/getAllCusts",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
      getClient: "session/getClient",
      fieldExists: "fdict/fieldExists",
    }),
    showServiceRep(): boolean {
      return this.fieldExists("CUST", "SERVICE.REP");
    },
  },
  data() {
    return {
      rangeStart: 1,
      rangeEnd: 100,
      rowSize: 50,
      first: 0,
      rows: 50,
      page: 0,
    };
  },
  methods: {
    ...mapActions({
      addNewFieldService: "fieldServices/addNewFieldService",
      getMrkControl: "mrkControl/getMrkControl",
      fetchAllCustomers: "customer/getAllCustomers",
    }),
    handleClick(e: any) {
      const newOrders = this.getFieldServices.filter(
        (fso: FieldServiceObject) =>
          fso.old_record.order_no && fso.old_record.order_no.startsWith("New"),
      );
      const newOrderCount = newOrders.length + 1;
      this.addNewFieldService(`New-${newOrderCount}`);
      const cust_id = e.data.cust_id;
      this.$router.push({
        path: `/fieldservices/New-${newOrderCount}`,
        query: { cust_id: cust_id },
      });
    },
    pageClick(event: any) {
      this.rangeStart = 1;
      this.rangeEnd += 100;
      this.$emit("pageClick", {
        rangeStart: this.rangeStart,
        rangeEnd: this.rangeEnd,
      });
    },
    shippingAddress(customer: Customer) {
      return Utils.customerShippingAddress(customer);
    },
  },
});
